// FileUploadContext.tsx

import React, { createContext, useContext, useState, useEffect } from "react";
import { TaskItemData, apiUploadPdf, apiTrack, apiUploadPdfV2 } from "../libs/http/api";
import { Status } from "../page/Historical/components/HistoricalItem";
import { useRequest } from "ahooks";
import { message } from "antd";
import { t } from "../languages";
import { useAuth } from "./AuthContext";

interface FileUpdateInterface {
  onFileUpload: (fileName:string) => void;
}

type UploadContextType = {
  addFiles: (files: File[]) => void;
  clearFile:(fileName:string) => void;
  setListener: (listener: FileUpdateInterface) => void;
  fileStatuses: TaskItemData[];
};

const FileUploadContext = createContext<UploadContextType | undefined>(
  undefined
);

let listener: FileUpdateInterface | null = null;

export const FileUploadProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useAuth()
  const [files, setFiles] = useState<TaskItemData[]>([]);
  const track = useRequest(apiTrack, { manual: true });
  // 使用useRequest钩子初始化上传函数
  const { run: uploadFile } = useRequest(apiUploadPdfV2, {
    manual: true,
    onSuccess: (result: any, params) => {
      const fileName = params[0].name;
      listener?.onFileUpload(fileName);
      track.runAsync(window.location.href, 'Click', result.data, '延伸记录上传成功', result.data.code)
    },
    onError: (error: any, params) => {
      const fileName = params[0].name;
      setFiles(prevFiles => prevFiles.filter(file => file.pdf_name !== fileName));
      message.error(t("上传失败！"));
      track.runAsync(window.location.href, 'Click', error.response.data, '上传失败', error.response.data.code)
    },
  });

  const addFiles = async (newFiles: File[]) => {
    const timestamp = Date.now();
    const newFileStatuses = newFiles.map((file) => ({
      pdf_id: timestamp,
      pdf_name: file.name,
      progress: "0",
      status: Status.UPLOADING,
      is_downloadable: false,
      preview_page_completed: false,
      file: file,
      output_type:'pdf'
    }));
    setFiles((prevFiles) => [...prevFiles,...newFileStatuses]);

    const hasFree = await auth.hasFreeCount()
    uploadFile(newFiles[0],hasFree, (progress: number) => {
      console.log("进度", progress);
      newFileStatuses[0].progress = progress.toString();
      setFiles((prevFiles) => [...prevFiles]);
    });

    //
  };

  const setListener = (lins: FileUpdateInterface) => {
    listener = lins;
  };

  const clearFile = (fileName:string) => {
    setFiles(prevFiles => {
        return prevFiles.filter(item => item.pdf_name !== fileName)
    });
  }

  return (
    <FileUploadContext.Provider
      value={{ addFiles, fileStatuses: files, setListener,clearFile }}>
      {children}
    </FileUploadContext.Provider>
  );
};

export const useUpload = () => {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error("useUpload must be used within a FileUploadProvider");
  }
  return context;
};
