import {
  Button,
  Checkbox,
  Flex,
  Layout,
  Modal,
  Popconfirm,
  Space,
  Spin,
  message,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import icon_download from "../../../assets/historical/icon_download@2x.png";
import icon_fold from "../../../assets/historical/icon_fold@2x.png";
import icon_unfold from "../../../assets/historical/icon_unfold@2x.png";
import icon_delete from "../../../assets/historical/icon_close@2x.png";
import pay_sel from "../../../assets/historical/pay_icon_sel@2x.png";
import arrow from "../../../assets/home/arrow.png"
import add_icon from "../../../assets/home/detail_icon_add@2x.png"
import icon_sel from "../../../assets/home/icon_sel@2x.png"
import icon_unsel from "../../../assets/home/icon_unsel@2x.png"
import { CheckboxChangeEvent } from "antd/es/checkbox";
import "./styles.css";
import {
  TaskItemData,
  apiCreatePaymentIntent,
  apiDdownloadPdf,
  apiDdownloadTrPdf,
  apiDeletePdf,
  apiGetPreview,
  apiGetTranslatePreview,
  apiPaymentForPdf,
  apiUpdatePdfTaskSettings,
} from "../../../libs/http/api";
import icon_pdf from "../../../assets/historical/pdf@2x.png";
import { useRequest } from "ahooks";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { t } from "../../../languages";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { calculateLayoutWidth, isMobile, px2vw, mToPcPx } from "../../../libs/utils";
import styled from "styled-components";
import CheckoutForm from "../../Payment/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useVoerkaI18n } from "@voerkai18n/react";
import useDetailPolling from "./DetailPolling";
import { logEvent } from "../../../libs/analytics";
import { useAuth } from "../../../components/AuthContext";
import useTrDetailPolling from "./TrDetailPolling";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//正式
const stripePromise = loadStripe(
  "pk_live_51O6o1BDkoOhDlqLb4znpIYocgnDFVqiQuACFD6hiCaxz4gWMJUEe7B2YqNuIOdgFwyMSdHOdMzyMBnHtfurjFZMO000tMUZTtc"
);


// 测试
// const stripePromise = loadStripe(
//   "pk_test_51O6o1BDkoOhDlqLbrAg1wrG6nFiRGzJhJ3mtVMCj4ysCFVSYWqRILaqYbQf6gHc4XBzYVVIOUWnJ059E2VenehtU00u7eSumiE"
// );

// 状态枚举
export enum Status {
  UPLOADING = "UPLOADING", //上传中
  PENDING = "PENDING", // 上传完成准备转化
  PROCESSING = "PROCESSING", // 转化中
  COMPLETED = "COMPLETED", // 已完成
  FAILED = "FAILED", // 处理失败
  READY = "READY", // 准备上传
  LOADING = "LOADING", // 加载
}

// 预览状态枚举
export enum PreviewStatus {
  PROCESSING = "PROCESSING", // 转化中
  COMPLETED = "COMPLETED", // 已完成
  FAILED = "FAILED", // 处理失败
}

let convert_type: Set<string> = new Set(); // 选择的转化数据

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: "/standard_fonts/",
};

//选择框
interface CheckBoxProps {
  title: string; //选择框内容
  status: Status;
}

interface ItemProps {
  data: TaskItemData; // 任务数据
  onUpdateCompleted(): void; // 完成通知刷新列表
}

function useApi(item_status: Status, props: ItemProps) {
  const [clientSecret, setClientSecret] = useState("");

  const [isOpen, setIsOpen] = useState(false); // 是否展开
  const [downloadType, setDownloadType] = useState(1); // 当前选择

  const [ocrPreviewData, setOcrPreivewData] = useState<string[]>([]);
  const [trPreviewData, setTrPreivewData] = useState<string[]>([]);

  const [preivewSelect, setPreviewSelect] = useState<string>("ocr") //预览时选中
  const [preivewData, setPreviewData] = useState<string[]>([]); // 预览图

  // const upload = useRequest(apiUploadPdf, { manual: true }); // pdf上传
  // const { runAsync: runUpload } = upload;

  const preview = useRequest(apiGetPreview, { manual: true }); // 获取pdf预览图
  const { runAsync: getPreview } = preview;

  const trPreview = useRequest(apiGetTranslatePreview, { manual: true }); // 获取pdf预览图
  const { runAsync: getTrPreview } = trPreview;

  const paymentInfo = useRequest(apiPaymentForPdf, { manual: true }); // 获取付款信息
  const { runAsync: getPaymentInfo } = paymentInfo;

  const createIntent = useRequest(apiCreatePaymentIntent, { manual: true }); // 创建支付意图
  const { runAsync: create } = createIntent;

  const deletePdf = useRequest(apiDeletePdf, { manual: true }); // 删除PDF
  const { runAsync: runDelete } = deletePdf;

  const downloadpdf = useRequest(apiDdownloadPdf, { manual: true }); //下载PDF
  const { runAsync: download } = downloadpdf;

  const downloadTrpdf = useRequest(apiDdownloadTrPdf, { manual: true }); //下载PDF
  const { runAsync: downloadTr } = downloadTrpdf;

  const updateSettings = useRequest(apiUpdatePdfTaskSettings, { manual: true }); //下载PDF
  const { runAsync: apiUpdateSettings } = updateSettings;

  //异步轮询 查询预览图是否制作完成，制作完成直接展示
  // const previewAsync = useCallback(async () => {
  //   let intervalId: any;
  //   try {
  //     const response = await getPreview(props.data.pdf_id);
  //     if (response.data.code === 200) {
  //       setOcrPreivewData(response.data.data.preview_png)
  //       if (preivewSelect === 'ocr') {
  //         setPreviewData(response.data.data.preview_png)
  //       }
  //       // props.data.preview_page_completed = true;
  //       console.log("获取图片成功");
  //       // setIsOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("请求出错------", error);
  //     intervalId = setTimeout(previewAsync, 5000);
  //   }

  //   return () => clearInterval(intervalId);
  // }, [getPreview, props.data]);

  //异步轮询 查询翻译预览图是否制作完成，制作完成直接展示
  // const previewTrAsync = useCallback(async () => {
  //   let intervalId: any;
  //   try {
  //     const response = await getTrPreview(props.data.pdf_id);
  //     if (response.data.code === 200) {
  //       setTrPreivewData(response.data.data.preview_png)
  //       // props.data.preview_page_completed = true;
  //       console.log("获取翻译图片成功");
  //       if (preivewSelect === 'tr') {
  //         setPreviewData(response.data.data.preview_png)
  //       }
  //       // setIsOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("请求出错======", error);
  //     intervalId = setTimeout(previewTrAsync, 5000);
  //   }

  //   return () => clearInterval(intervalId);
  // }, [getTrPreview, props.data]);

  // useEffect(() => {
  //   let isMounted = true;

  //   if (
  //     isMounted
  //   ) {
  //     previewAsync();
  //     previewTrAsync();
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [item_status]);

  return {
    isOpen,
    setIsOpen,
    downloadType,
    setDownloadType,
    preview,
    paymentInfo,
    getPaymentInfo,
    create,
    clientSecret,
    setClientSecret,
    runDelete,
    download,
    getPreview,
    getTrPreview,
    setOcrPreivewData,
    setTrPreivewData,
    downloadTr,
    ocrPreviewData,
    trPreviewData,
    preivewData,
    setPreviewData,
    preivewSelect,
    setPreviewSelect,
    apiUpdateSettings

  };
}

export function CheckBoxItem(props: CheckBoxProps) {
  let item_status = props.status; // 当前Item状态

  return (
    <Checkbox
      onChange={(e: CheckboxChangeEvent) => {
        if (convert_type.has(props.title)) {
          convert_type.delete(props.title);
        } else {
          convert_type.add(props.title);
        }
      }}
      disabled={
        !convert_type.has(props.title) &&
        (item_status === Status.PROCESSING || item_status === Status.COMPLETED)
      }
    >
      {props.title}
    </Checkbox>
  );
}

export default function HistoricalItem(props: ItemProps) {
  const auth = useAuth()
  const { activeLanguage } = useVoerkaI18n();
  const [task_data, setTaskData] = useState(props.data);
  const [trTask_data, setTrTaskData] = useState(props.data);
  const [open, setOpen] = useState(false); //model框
  const [isPreview, setPreivew] = useState(false); //预览界面
  const [spinning, setSpinning] = React.useState<boolean>(false); // 全屏加载框
  const [clientSecret, setClientSecret] = useState("");
  const [trResult, setTrresult] = useState(""); // 翻译目标
  const [ocrDropdowns, setOcrDropdowns] = useState<string[]>([activeLanguage ?? 'en']);
  const [trDropdowns, setTrDropdowns] = useState<string[]>([activeLanguage ?? 'en']);


  const [item_status, setItemStatus] = useState<Status>(Status.LOADING);
  const [trItem_status, setTrItemStatus] = useState<Status>(Status.LOADING);
  const api = useApi(item_status, props);
  const [isPro, setPro] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState<string>('pdf'); //输出格式
  const [trsSelectedFormat, setTrSelectedFormat] = useState<string>(''); //输出格式
  const [isTranslate, setIsTranslate] = useState<boolean>(false); //是否为翻译模式

  const handleChange = (format: string) => {
    setSelectedFormat(selectedFormat === format ? '' : format); // 允许取消选中
  };

  const trHandleChange = (format: string) => {
    setTrSelectedFormat(trsSelectedFormat === format ? '' : format); // 允许取消选中
  };

  const usePolling = useDetailPolling(
    props.data.pdf_id,
    (data: TaskItemData) => {
      setTaskData(data); // ocr
      setItemStatus(data.status as Status);
      setSelectedFormat(data.output_type)
      if (data.status != Status.PROCESSING) {
        useTrPolling.stop()
      }
    },
  );

  const useTrPolling = useTrDetailPolling(
    props.data.pdf_id,
    (data: TaskItemData) => { // 翻译
      setTrTaskData(data);
      setTrItemStatus(data.status as Status);
      setTrSelectedFormat(data.output_type)
      if (data.status != Status.PROCESSING) {
        useTrPolling.stop()
      }
    }
  )

  useEffect(() => {
    useTrPolling.start()

    if (activeLanguage === 'zh') {
      setTrresult("en")
    } else {
      setTrresult("zh")
    }
  }, [])

  useEffect(() => {
    setItemStatus(task_data.status as Status);
    setTrItemStatus(trTask_data.status as Status);
  }, [task_data, trTask_data]);

  useEffect(() => {
    if (item_status === Status.PROCESSING) {
      usePolling.start();
    } else if (item_status === Status.COMPLETED) {
      usePolling.stop();
    }
  }, [item_status]);

  useEffect(() => {
    if (trItem_status === Status.PROCESSING) {
      useTrPolling.start();
    } else if (trItem_status === Status.COMPLETED) {
      useTrPolling.stop();
    }
  }, [trItem_status]);

  useEffect(() => {
    // if (task_data.preview_page_completed) {
    //   api.getPreview(props.data.pdf_id).catch((error) => { });
    // }
  }, []);

  //添加选项
  const addOcrDropdown = () => {
    setOcrDropdowns([...ocrDropdowns, ""]); // 添加一个新的空选项
  };

  //添加选项
  const addTrDropdown = () => {
    setTrDropdowns([...trDropdowns, ""]); // 添加一个新的空选项
  };

  //ocr选项改变
  const ocrHandleDropChange = (index: number, value: string) => {
    const updatedDropdowns = [...ocrDropdowns];
    updatedDropdowns[index] = value;
    setOcrDropdowns(updatedDropdowns);
  };

  //翻译选项改变
  const trHandleDropChange = (index: number, value: string) => {
    const updatedDropdowns = [...trDropdowns];
    updatedDropdowns[index] = value;
    setTrDropdowns(updatedDropdowns);
  };

  //翻译选项改变
  const trResultHandleDropChange = (value: string) => {
    setTrresult(value);
  };

  //展开PDF信息
  const openClick = function () {
    api.setIsOpen(!api.isOpen);
  };

  const onPrevieeSelect = (choose: string) => {

    api.setPreviewSelect(choose)
    if (choose === "ocr") {
      api.setPreviewData(api.ocrPreviewData)
    } else {
      api.setPreviewData(api.trPreviewData)
    }
  }


  //Pro点击
  const onProClick = async function (_isPro: boolean) {
    setPro(_isPro);
    clickPayemnt(_isPro, isTranslate);
    logEvent("Home", "pdf_pro_click", "pro");
  };

  //前往支付
  const clickPayemnt = (_isPro: boolean, translate: boolean) => {
    logEvent("Home", "payment_info_click", "payment info");
    setSpinning(true);
    api
      .getPaymentInfo(
        task_data.pdf_id,
        activeLanguage ?? "en",
        translate,
        _isPro ? _isPro : undefined,
      )
      .then((resp) => {
        //toPayment(resp.data.data.payment_gateways,resp.data.data.pricing_code,props.data.pdf_id)
        toPayment(
          // ["alipay", "card", "wechat_pay"],
          resp.data.data.payment_gateways,
          resp.data.data.pricing_code,
          task_data.pdf_id
        );
      })
      .catch((error) => {
        setSpinning(false);
        if (error.response) {
          message.error(error.response.data.message);
        }
        //message.error(err)
      });
  };

  const toPayment = (
    payment_methods: Array<string>,
    pricing_code: string,
    pdf_id: number
  ) => {
    api
      .create({
        payment_methods,
        pdf_id,
        pricing_code,
      })
      .then((resp2) => {
        setClientSecret(resp2.data.data.client_secret);
        setSpinning(false);
        setOpen(true);
      })
      .catch((error) => {
        setSpinning(false);
        if (error.response) {
          message.error(error.response.data.message);
        }
      });
  };

  //删除PDF
  const deletePdf = () => {
    logEvent("Home", "delete_click", "delete");
    api
      .runDelete(task_data.pdf_id)
      .then((resp) => {
        message.success(resp.data.message);
        props.onUpdateCompleted();
      })
      .catch((err) => { });
  };

  //下载PDF
  const dowloadPdf = useCallback(() => {
    logEvent("Home", "download_click", "download");
    if (task_data.is_downloadable === false) {
      message.info(t("正在制作中..."));
      return;
    }
    message.info(t("正在下载中..."));
    api
      .download(task_data.pdf_id)
      .then((resp) => {
        handleDownload(resp.data.data.compiled_pdf, task_data.pdf_name);
      })
      .catch((err) => { });
  }, [task_data]);

  //下载翻译PDF
  const dowloadTrPdf = useCallback(() => {
    logEvent("Home", "download_click", "download");
    if (task_data.is_downloadable === false) {
      message.info(t("正在制作中..."));
      return;
    }
    message.info(t("正在下载中..."));
    api
      .downloadTr(task_data.pdf_id)
      .then((resp) => {
        handleDownload(resp.data.data.compiled_pdf, task_data.pdf_name);
      })
      .catch((err) => { });
  }, [task_data]);

  //触发下载事件
  function handleDownload(http_url: string, file_name: string) {
    // 发起文件下载请求
    fetch(http_url)
      .then((response) => response.blob())
      .then((blob) => {
        // 创建 Blob 对象后，使用 URL.createObjectURL() 方法生成下载链接
        const url = window.URL.createObjectURL(blob);

        // 创建一个隐藏的 <a> 标签来触发下载
        const a = document.createElement("a");
        a.href = url;
        a.download = file_name;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        // 释放 URL 对象
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("文件下载失败:", error));
  }

  //异步轮询 查询预览图是否制作完成，制作完成直接展示
  // const previewAsync = useCallback(async () => {
  //   let intervalId: any;
  //   try {
  //     const response = await getPreview(props.data.pdf_id);
  //     if (response.data.code === 200) {
  //       setOcrPreivewData(response.data.data.preview_png)
  //       if (preivewSelect === 'ocr') {
  //         setPreviewData(response.data.data.preview_png)
  //       }
  //       // props.data.preview_page_completed = true;
  //       console.log("获取图片成功");
  //       // setIsOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("请求出错------", error);
  //     intervalId = setTimeout(previewAsync, 5000);
  //   }

  //   return () => clearInterval(intervalId);
  // }, [getPreview, props.data]);

  async function getOCR() {
    try {
      const response = await api.getPreview(props.data.pdf_id);
      console.log(response, 'getOCR-response');

      if (response.data.code === 200) {
        api.setOcrPreivewData(response.data.data.preview_png)
        if (api.preivewSelect === 'ocr') {
          api.setPreviewData(response.data.data.preview_png)
        }
        props.data.preview_page_completed = true;
        console.log("获取图片成功");
        // setIsOpen(true);
      }
    } catch (error) {
      console.error("请求出错------", error);
    }
  };

  // //异步轮询 查询翻译预览图是否制作完成，制作完成直接展示
  async function getTr() {
    try {
      const response = await api.getTrPreview(props.data.pdf_id);
      console.log(response, 'getTr-response');

      if (response.data.code === 200) {
        api.setTrPreivewData(response.data.data.preview_png)
        console.log("获取翻译图片成功");
        if (api.preivewSelect === 'tr') {
          api.setPreviewData(response.data.data.preview_png)
        }
      }
    } catch (error) {
      console.error("请求出错======", error);
    }
  };

  const openPreivew = () => {
    setPreivew(true)
    getTr();
    getOCR();
    if (api.preivewSelect === "ocr") {
      api.setPreviewData(api.ocrPreviewData)
    } else {
      api.setPreviewData(api.trPreviewData)
    }

  }

  function ocrConventClick() {
    if (task_data.status === Status.COMPLETED) {
      dowloadPdf()
    } else {
      if (!selectedFormat) {
        message.error(t('请选择输出格式'))
        return
      }
      setIsTranslate(false)
      clickPayemnt(false, false)
    }

  }

  function trConventClick() {
    if (trTask_data.status === Status.COMPLETED) {
      dowloadTrPdf()
    } else {
      if (trResult === '') {
        message.error(t('请选择翻译目标语言'))
        return
      }
      if (!trsSelectedFormat) {
        message.error(t('请选择输出格式'))
        return
      }
      api.apiUpdateSettings({
        task_id: props.data.pdf_id,
        source_languages: trDropdowns,
        target_language: trResult,
        output_formats: [selectedFormat]
      })
      setIsTranslate(true)
      clickPayemnt(false, true)
    }

  }

  return (
    <>
      <LayoutBox>
        <ItemMainStyle is_open={api.isOpen}>
          <ItemMainTopStyle>
            <TitleBox>
              <TitleImage src={icon_pdf} />
              <TitleStyle>{task_data.pdf_name}</TitleStyle>
            </TitleBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {(() => {
                switch (item_status) {
                  case Status.UPLOADING:
                    return (
                      <StatusTvStyle color="#F9A24E">
                        {t("正在上传")}
                      </StatusTvStyle>
                    );
                  case Status.PENDING:
                  case Status.PROCESSING:
                  case Status.COMPLETED:
                  case Status.FAILED:
                    return (
                      <Flex justify="center" align="center">
                        {/* {item_status === Status.READY ? "生成（多选）：" : ""}
                        <CheckBoxItem title="WORD" />
                        <CheckBoxItem title="HTML" />
                        <CheckBoxItem title="EPUB" />
                        <CheckBoxItem title="PDF" /> */}

                        {(() => {
                          if (item_status === Status.PENDING) {
                            return (
                              <StatusTvStyle color="#4E89F9">
                                {t("已上传")}
                              </StatusTvStyle>
                            );
                          } else if (item_status === Status.PROCESSING) {
                            return (
                              <StatusTvStyle color="#F9A24E">
                                {t("处理中")}
                              </StatusTvStyle>
                            );
                          } else if (item_status === Status.COMPLETED) {
                            return (
                              <StatusTvStyle color="#02BB00">
                                {t("已完成")}
                              </StatusTvStyle>
                            );
                          } else if (item_status === Status.FAILED) {
                            return (
                              <StatusTvStyle color="#FD4246">
                                {t("处理失败")}
                              </StatusTvStyle>
                            );
                          }
                        })()}
                      </Flex>
                    );
                }
              })()}
            </div>
          </ItemMainTopStyle>

          <ItemMainBottomStyle is_open={api.isOpen}>
            {isMobile() ? (
              <>
                <HRightBox>
                  <HrightBoxLabel onClick={openPreivew}>{t("预览")}</HrightBoxLabel>
                  <div style={{ cursor: 'pointer' }} onClick={openClick}>
                    <HrightBoxLabel>{
                      api.isOpen ? t("收起处理文件") : t("展开处理文件")
                    }</HrightBoxLabel>
                    <Arrow style={{
                      transform: api.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                      transition: 'transform 0.3s', // 添加平滑过渡效果
                    }} src={arrow}></Arrow>
                  </div>
                </HRightBox>
              </>
            ) : (
              <>
                <HRightBox>
                  <HrightBoxLabel onClick={openPreivew}>{t("预览")}</HrightBoxLabel>
                  <div style={{ cursor: 'pointer' }} onClick={openClick}>
                    <HrightBoxLabel>{
                      api.isOpen ? t("收起处理文件") : t("展开处理文件")
                    }</HrightBoxLabel>
                    <Arrow style={{
                      transform: api.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                      transition: 'transform 0.3s', // 添加平滑过渡效果
                    }} src={arrow}></Arrow>
                  </div>
                </HRightBox>
              </>
            )}
          </ItemMainBottomStyle>
        </ItemMainStyle>
        {api.isOpen ? ( //展开后的样式
          <ExpendedBox>
            <ExpandedContent>
              <ExpendedTitle>{t("OCR")}</ExpendedTitle>
              <ExpendedTitle2>{t("请选择源文件中包含的语言，将提升识别精度，如未选择则智能识别")}</ExpendedTitle2>
              <ExpendedTitle3>{t("源文件包含语言：")}</ExpendedTitle3>
              <DropdownContainer>
                {ocrDropdowns.map((selectedOption, index) => (
                  <StyledSelect
                    disabled={[Status.COMPLETED, Status.PROCESSING].includes(item_status)}
                    key={index}
                    value={selectedOption}
                    onChange={(e) => ocrHandleDropChange(index, e.target.value)}
                  ><option value="" disabled>
                      {t("请选择")}
                    </option>
                    <option value="zh">{t("中文")}</option>
                    <option value="en">{t("英文")}</option>
                  </StyledSelect>))}
                <AddBox disabled={[Status.COMPLETED, Status.PROCESSING].includes(item_status)} onClick={() => {
                  addOcrDropdown()
                }}><AddIcon src={add_icon}></AddIcon></AddBox>
              </DropdownContainer>
              <ExpendedTitle3>{t("输出文件格式：")}</ExpendedTitle3>

              <CheckboxContainer>
                {/* PDF 复选框 */}
                <StyledLabel>
                  <StyledInput
                    disabled={[Status.COMPLETED, Status.PROCESSING].includes(item_status)}
                    type="checkbox"
                    checked={selectedFormat === "pdf"}
                    onChange={() => handleChange("pdf")}
                  />
                  <Icon
                    src={selectedFormat === "pdf" ? icon_sel : icon_unsel}
                    alt="PDF"
                  />
                  <Text>PDF</Text>
                </StyledLabel>

                {/* Markdown 复选框 */}
                <StyledLabel>
                  <StyledInput
                    disabled={[Status.COMPLETED, Status.PROCESSING].includes(item_status)}
                    type="checkbox"
                    checked={selectedFormat === "markdown"}
                    onChange={() => handleChange("markdown")}
                  />
                  <Icon
                    src={selectedFormat === "markdown" ? icon_sel : icon_unsel}
                    alt="Markdown"
                  />
                  <Text>Markdown</Text>
                </StyledLabel>
              </CheckboxContainer>
              <ConvertBox>
                <ConventButton onClick={ocrConventClick} disabled={item_status === Status.PROCESSING}>
                  {(() => {
                    switch (item_status) {
                      case Status.PROCESSING:
                        return (
                          <div> {t("制作中")}</div>
                        );
                      case Status.COMPLETED:
                        return (
                          <div> {t("下载")}</div>
                        );
                      default:
                        return (
                          <div> {t("开始制作")}</div>
                        );
                    }
                  })()}

                </ConventButton>
                <ConvertLabel>
                  {(() => {
                    switch (item_status) {
                      case Status.PROCESSING:
                        return (
                          <div>{t("正在转换文件格式:")}{task_data.progress}</div>
                        );

                    }
                  })()}
                </ConvertLabel>
              </ConvertBox>
            </ExpandedContent>
            <Line />

            <ExpandedContent>
              <ExpendedTitle>{t("翻译")}</ExpendedTitle>
              <ExpendedTitle2>{t("请选择源文件中包含的语言，将提升识别精度，如未选择则智能识别")}</ExpendedTitle2>
              <ExpendedTitle3>{t("源文件包含语言：")}</ExpendedTitle3>
              <DropdownContainer>
                {trDropdowns.map((selectedOption, index) => (
                  <StyledSelect
                    disabled={[Status.COMPLETED, Status.PROCESSING].includes(trItem_status)}
                    key={index}
                    value={selectedOption}
                    onChange={(e) => trHandleDropChange(index, e.target.value)}
                  ><option value="" disabled>
                      {t("请选择")}
                    </option>
                    <option value="zh">{t("中文")}</option>
                    {/* <option value="en">{t("英文")}</option> */}
                  </StyledSelect>))}
                {/* <AddBox disabled={[Status.COMPLETED, Status.PROCESSING].includes(trItem_status)} onClick={() => {
                  addTrDropdown()
                }}>
                  <AddIcon src={add_icon}></AddIcon></AddBox> */}
              </DropdownContainer>

              <ExpendedTitle3>{t("翻译目标语言：")}</ExpendedTitle3>
              <StyledSelect
                disabled={[Status.COMPLETED, Status.PROCESSING].includes(trItem_status)}
                value={trResult}
                onChange={(e) => trResultHandleDropChange(e.target.value)}
              ><option value="" disabled>
                  {t("请选择")}
                </option>
                {/* <option value="zh">{t("中文")}</option> */}
                <option value="en">{t("英文")}</option>
              </StyledSelect>
              <ExpendedTitle3>{t("输出文件格式：")}</ExpendedTitle3>

              <CheckboxContainer>
                {/* PDF 复选框 */}
                <StyledLabel>
                  <StyledInput
                    disabled={[Status.COMPLETED, Status.PROCESSING].includes(trItem_status)}
                    type="checkbox"
                    checked={trsSelectedFormat === "pdf"}
                    onChange={() => trHandleChange("pdf")}
                  />
                  <Icon
                    src={trsSelectedFormat === "pdf" ? icon_sel : icon_unsel}
                    alt="PDF"
                  />
                  <Text>PDF</Text>
                </StyledLabel>

                {/* Markdown 复选框 */}
                <StyledLabel>
                  <StyledInput
                    disabled={[Status.COMPLETED, Status.PROCESSING].includes(trItem_status)}
                    type="checkbox"
                    checked={trsSelectedFormat === "markdown"}
                    onChange={() => trHandleChange("markdown")}
                  />
                  <Icon
                    src={trsSelectedFormat === "markdown" ? icon_sel : icon_unsel}
                    alt="Markdown"
                  />
                  <Text>Markdown</Text>
                </StyledLabel>
              </CheckboxContainer>
              <ConvertBox>
                <ConventButton onClick={trConventClick} disabled={trItem_status === Status.PROCESSING}>
                  {(() => {
                    switch (trItem_status) {
                      case Status.PROCESSING:
                        return (
                          <div> {t("翻译中")}</div>
                        );
                      case Status.COMPLETED:
                        return (
                          <div> {t("下载")}</div>
                        );
                      default:
                        return (
                          <div> {t("开始翻译")}</div>
                        );

                    }
                  })()}
                </ConventButton>
                <ConvertLabel>
                  {(() => {
                    switch (trItem_status) {
                      case Status.PROCESSING:
                        return (
                          <div>{t("正在翻译文件:")}{trTask_data.progress}</div>
                        );

                    }
                  })()}
                </ConvertLabel>
              </ConvertBox>

            </ExpandedContent>
          </ExpendedBox>
        ) : (
          <div />
        )}
      </LayoutBox>
      <Modal
        centered
        width={1224}
        open={isPreview}
        onCancel={() => {
          setPreivew(false)
          api.setPreviewSelect("ocr")
        }}
        footer={null}
      >
        {(() => {
          if (api.preivewData.length == 0) {
            return (<PorcessingBox>
              <label>{t("预览页面加载中，请稍后…")}</label>
              <div style={{ height: '12px' }}></div>
              <Spin></Spin>
              {/* <ProgressBarContainer>
                <ProgressBarWrapper>
                  <ProgressBar progress={12} />
                </ProgressBarWrapper>
                <ProgressText>{12}%</ProgressText>
              </ProgressBarContainer> */}
            </PorcessingBox>)
          } else {
            return (<PreviewBox>
              <PreviewContent>
                {api.preivewData
                  .map((item) =>
                    item ? (
                      <PreviewImgStyle key={item} src={item} />
                    ) : (
                      <div></div>
                    )
                  )}
                {api.preivewData.length === 0 ? (
                  <PreviewItemStyle />
                ) : (
                  <div />
                )}
              </PreviewContent>
              <PreviewMaskStyle>
                <PreviewSelectButton>
                  <PreivewButton isSelect={api.preivewSelect === "ocr"} onClick={() => { onPrevieeSelect("ocr") }}>{t("OCR")}</PreivewButton>
                  <PreivewButton isSelect={api.preivewSelect === "tr"} onClick={() => { onPrevieeSelect("tr") }}>{t("翻译")}</PreivewButton>
                </PreviewSelectButton>
              </PreviewMaskStyle>

            </PreviewBox>)
          }
          // switch (api.preivew_status) {
          //   case PreviewStatus.PROCESSING:
          //     return (<PorcessingBox>
          //       <label>{t("预览页面加载中，请稍后…")}</label>
          //       <ProgressBarContainer>
          //         <ProgressBarWrapper>
          //           <ProgressBar progress={12} />
          //         </ProgressBarWrapper>
          //         <ProgressText>{12}%</ProgressText>
          //       </ProgressBarContainer>
          //     </PorcessingBox>)
          //   case PreviewStatus.COMPLETED:

          //   case PreviewStatus.FAILED:
          //     return (
          //       <PorcessingFail>
          //         <label>{t("加载失败")}</label>
          //         <FailButton>{t("重新制作")}</FailButton>
          //       </PorcessingFail>
          //     );
          // }
        })()}
      </Modal>
      <Modal
        width={600}
        title={t("支付下载")}
        style={{
          fontSize: 28,
        }}
        centered
        open={open}
        footer={null}
        onOk={() => setOpen(false)}
        onCancel={async () => {
          setPro(true);
          setOpen(false);
          logEvent("Home", "payment_cancel", "Cancel");
          if (await auth.hasFreeCount()) {
            auth.setUploadFail(true)
          }
        }}
      >
        <center>
          <Space size={30}>
            <ModalTopButton onClick={() => onProClick(false)} isPro={!isPro}>
              <TBContent>
                <TopButtonLabel>OCR</TopButtonLabel>
                <Flex justify="center" align="center" vertical>
                  <TopButtonLabelRight>99%</TopButtonLabelRight>
                  <TopButtonLabelRB>{t("识别正确率")}</TopButtonLabelRB>
                </Flex>
              </TBContent>
              {!isPro && <img className="sel" src={pay_sel} alt="" />}
            </ModalTopButton>
            <ModalTopButton onClick={() => onProClick(true)} isPro={isPro}>
              <TopIcon>{t("推荐")}</TopIcon>
              <TBContent>
                <TopButtonLabel>OCR Pro</TopButtonLabel>
                <Flex justify="center" align="center" vertical>
                  <TopButtonLabelRight>99.5%</TopButtonLabelRight>
                  <TopButtonLabelRB>{t("识别正确率")}</TopButtonLabelRB>
                </Flex>
              </TBContent>
              {isPro && <img className="sel" src={pay_sel} alt="" />}
            </ModalTopButton>
          </Space>
        </center>
        {clientSecret ? (
          <center>
            <Elements
              options={{
                clientSecret: clientSecret,
                appearance: { theme: "stripe" },
              }}
              stripe={stripePromise}
            >
              <CheckoutForm
                is_translate={isTranslate}
                update={() => {
                  if (isTranslate) {
                    useTrPolling.start()
                  } else {
                    usePolling.start();
                  }
                  setOpen(false);
                }}
                pdf_id={task_data.pdf_id}
                data={api.paymentInfo.data?.data.data}
              />
            </Elements>
          </center>
        ) : (
          <div></div>
        )}
      </Modal>
      <Spin style={{ zIndex: 9999 }} spinning={spinning} fullscreen />
    </>
  );
}

const LayoutBox = styled.div`
  width: 100%;
  height: auto;
`;

const TitleImage = styled.img`
  width: 32px;
  height: 32px;

  @media only screen and (max-width: 768px) {
    width: 123px;
    height: 123px;
  }
`;

const ExpandedBox = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 80px;
  }
`;

const TitleBox = styled.div`
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: start;
    margin-left: 80px;
  }
`;

const TitleStyle = styled.div`
  max-width: 800px;
  font-weight: 400;
  font-size: 18px;
  color: #222222;
  margin-left: 10px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  font-family: Montserrat Bold;
  text-overflow: ellipsis;

  @media only screen and (max-width: 768px) {
    font-size: 72px;
    line-height: 92px;
    margin-left: 41px;
  }
`;

interface StatusTvStyleProps {
  color: string;
}

const StatusTvStyle = styled.div<StatusTvStyleProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  padding-left: 6px;
  padding-right: 6px;
  height: 32px;
  border-radius: 6px;
  font-size: 16px;
  border: 2px solid ${(props) => props.color};

  @media only screen and (max-width: 768px) {
    border-radius: 31px;
    border: 5px solid ${(props) => props.color};
    margin-right: 80px;

    font-size: 51px;
    line-height: 77px;
    height: 123px;
    padding: 26px;
  }
`;

const ConvertTv = styled.div`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: left;
  font-style: normal;

  a {
    color: #f9a24e;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 61px;
    line-height: 77px;
  }
`;

const ConvertTvGrey = styled.div`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  margin-top: 32px;
  text-align: center;
  a {
    color: #f9a24e;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 46px;
    line-height: 56px;
    margin-top: 64px;
  }
`;

const ConvertTvPreview = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  color: #222222;
  margin-top: 130px;
  font-weight: bold;
  a {
    color: #f9a24e;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 52px;
    line-height: 62px;
    margin-top: 240px;
  }
`;

const ItemMainStyle = styled.div<{ is_open: boolean }>`
  width: 100%;
  display: flex;
  background: white;
  justify-content: end;
  align-items: center;
  border-radius: ${(props) => (props.is_open ? "24px 24px 0 0" : "24px")};

  height: 100px;

  /* 上边框 */
  border-top-style: solid; /* 上边框样式 */
  border-top-width: 1px; /* 上边框宽度 */
  border-top-color: #ebebeb; /* 上边框颜色 */

  /* 右边框 */
  border-right-style: solid; /* 右边框样式 */
  border-right-width: 1px; /* 右边框宽度 */
  border-right-color: #ebebeb; /* 右边框颜色 */

  /* 左边框 */
  border-left-style: solid; /* 左边框样式 */
  border-left-width: 1px; /* 左边框宽度 */
  border-left-color: #ebebeb; /* 左边框颜色 */

  @media only screen and (max-width: 768px) {
    height: 522px;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ItemMainTopStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ItemMainBottomStyle = styled.div<{ is_open: boolean }>`
  display: flex;
  flex-direction: row;

  margin-right: 30px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: end;

  /* 下边框 */
  border-bottom-style: solid; /* 下边框样式 */
  border-bottom-width: 1px; /* 下边框宽度 */
  border-bottom-color: #ebebeb; /* 下边框颜色 */

  @media only screen and (max-width: 768px) {
    background: #fdfbf5;
    justify-content: space-between;
    margin-right: 0px;
    border-radius: ${(props) => (props.is_open ? "0" : "0 0 24px 24px")};
    /* 下边框 */
    border-bottom-style: solid; /* 下边框样式 */
    border-bottom-width: ${(props) => (props.is_open ? "0" : "1px")};
    border-bottom-color: #ebebeb; /* 下边框颜色 */
  }
`;

const PreviewItemStyle = styled.div`
  width: 595px;
  height: 842px;
  background: white;
  border: 1px solid #ebebeb;
  animation: hide-item 2s ease-in;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 827px;
    height: 1172px;
  }
  .preview-png {
    display: block;
    width: 595px;
    height: 842px;
    @media only screen and (max-width: 768px) {
      width: 827px;
      height: 1172px;
    }
  }
`;

const PreviewMaskStyle = styled.div`
  width: 100%;
  height: 322px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 17%,
    rgba(255, 255, 255, 0.96) 40%,
    #ffffff 100%
  );
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999; // 设置层级高于 Flex
  display: flex;
  align-items: center;
  padding-bottom: 40px;

  flex-direction: column;
  justify-content: end;

  @media only screen and (max-width: 768px) {
    width: 1654px;
    height: 614px;
  }
`;

const DownloadButton = styled.button`
  cursor: pointer;
  margin-top: 24px;
  background: #fdc448;
  width: 300px;
  height: 60px;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 8px;
  border: none;
  margin-bottom: 16px;

  :hover {
    color: #ffffff;
  }
`;

const ModalTopButton = styled.button<{ isPro: boolean }>`
  position: relative;
  width: 255px;
  height: 70px;
  background: #ffffff;
  border-radius: 8px;
  border: 3px solid ${({ isPro }) => (isPro ? "#222222" : "#EBEBEB")};
  margin-top: 12px;
  margin-bottom: 12px;

  color: ${({ isPro }) => (isPro ? "#222222" : "#999999")};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  * {
    color: inherit;
  }

  @media only screen and (max-width: 768px) {
    width: 768px;
    height: 205px;
    border-radius: 20px;
  }
  .sel {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 38px;
    height: 38px;
    @media only screen and (max-width: 768px) {
      width: ${mToPcPx(24)};
      height: ${mToPcPx(24)};
    }
  }
`;

const TBContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TopButtonLabel = styled.label`
  width: auto;
  font-weight: bold;
  font-size: 22px;
  line-height: 64px;
  text-align: center;
  font-style: normal;
  margin-right: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 205px;
  }
`;

const TopButtonLabelRight = styled.label`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 92px;
  }
`;

const TopButtonLabelRB = styled.label`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    line-height: 92px;
  }
`;

const TopIcon = styled.label`
  position: absolute;
  left: -3px;
  top: -16px;
  padding: 0px 5px 0px;
  height: 32px;
  background: linear-gradient(180deg, #f49f9f 0%, #f53e3e 100%);
  border-radius: 24px 100px 100px 0px;

  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  font-style: normal;

   @media only screen and (max-width: 768px) {
    height: 82px;
    font-size: 46px;
    line-height: 82px;
    padding: 0px 25px 0px;
     top: -36px;
   }
`;

const ItemBottomSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  img {
    width: 92px;
    height: 92px;
  }

  font-family: Montserrat;
  font-weight: 400;
  font-size: 72px;
  color: #222222;
  line-height: 92px;
  text-align: center;
  font-style: normal;
`;

const HRightBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const HrightBoxLabel = styled.label`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: center;
  font-style: normal;
  margin-right: 12px;

  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
`

const Arrow = styled.img`
  margin-top: 4px;
  width: 10px;
  height: 18px;
`

const ExpendedBox = styled.div`
  width: 1520px;
  height: 1309px;
  background: #FFFFFF;
  border-radius: 0px 0px 24px 24px;
  border: 1px solid #EBEBEB;

  display: flex;

  flex-direction: column;

  align-items: center;
`

const ExpendedTitle = styled.label`
  height: 54px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  line-height: 54px;
  text-align: left;
  font-style: normal;
`
const ExpandedContent = styled.div`
width: 1520px;
  display: flex;
  flex-direction: column;
  padding: 60px;
`

const ExpendedTitle2 = styled.label`
  margin-top: 8px;
  height: 27px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  line-height: 27px;
  text-align: left;
  font-style: normal;
`

const ExpendedTitle3 = styled.label`
  margin-top: 40px;
  height: 27px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 18px;
  color: #222222;
  line-height: 27px;
  text-align: left;
  font-style: normal;
`

const StyledSelect = styled.select`
  width: 320px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  color: #333333;

  /* 移除默认箭头样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* 自定义箭头的右间距 */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23333333'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 16px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  margin-top: 16px;
`;

const AddBox = styled.button`
  width: 80px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  cursor: pointer;
`
const AddIcon = styled.img`
  width: 27px;
  height: 27px;
`

// 样式
const CheckboxContainer = styled.div`
  margin-top: 17px;
  display: flex;
  gap: 40px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const StyledInput = styled.input`
  display: none; /* 隐藏默认复选框 */

  &:disabled {
    cursor: not-allowed;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

const Text = styled.span`
  font-size: 18px;
  color: #333;
`;

const ConventButton = styled.button`
  width: 240px;
  height: 80px;
  background: #222222;
  border-radius: 8px;

  color: white;
  font-size: 24px;

  cursor: pointer;

  &:disabled {
    background: #666666;
    cursor: not-allowed;
  }
`

const Line = styled.div`
  width: 1410px;
  height: 2px;
  background: #EBEBEB;
`

const PreviewBox = styled.div`
  position: relative;
`

const PreviewContent = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
`
const PreviewImgStyle = styled.img`
  padding: 20px;
  width: 50%;
  height: 792px;
  background: white;
  animation: hide-item 2s ease-in;
  overflow: hidden;
  object-fit: contain;

  @media only screen and (max-width: 768px) {
    width: 827px;
    height: 1172px;
  }
`;

const PreviewSelectButton = styled.div`
  width: 256px;
  height: 58px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
  border-radius: 8px;
  border: 1px solid #EBEBEB;

  position: absolute;
  bottom: 0; /* 固定到底部 */
  left: 50%; /* 水平中心 */
  transform: translateX(-50%); /* 偏移自身宽度的一半，确保居中 */
  margin-bottom: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`
const PreivewButton = styled.button<{ isSelect: boolean }>`
  width: 120px;
  height: 42px;
  background: ${({ isSelect }) => (isSelect ? "#FDC448" : "white")}; /* 动态背景色 */
  border-radius: 8px;
  border: 0;
  font-size: 20px;
  color: #222222;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`
const PorcessingBox = styled.div`
  height: 792px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 20px;
    color: #222222;
    line-height: 29px;
    text-align: center;
    font-style: normal;
  }
`
// 进度条容器
const ProgressBarContainer = styled.div`
  display: flex; /* 使进度条与文字并排 */
  align-items: center; /* 垂直居中对齐 */
  margin-top: 38px;
`;

// 进度条外框
const ProgressBarWrapper = styled.div`
  width: 240px; /* 容器宽度 */
  height: 10px; /* 容器高度 */
  background-color: #f0f0f0; /* 背景色（灰色） */
  border-radius: 10px; /* 圆角 */
  overflow: hidden; /* 确保内部内容不会溢出 */
  position: relative; /* 相对定位用于内部进度条 */
`;

// 进度条
const ProgressBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%; /* 动态设置宽度 */
  height: 100%; /* 高度与容器一致 */
  background-color: #FDC448; /* 进度条颜色 */
  border-radius: 10px; /* 圆角 */
  transition: width 0.3s ease; /* 动画过渡效果 */
`;

// 进度文字
const ProgressText = styled.span`
  margin-left: 12px; /* 与进度条的间距 */
  font-size: 16px;
  color: #333;
  white-space: nowrap; /* 防止文字换行 */
`;

//加载失败
const PorcessingFail = styled.div`
  height: 792px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 18px;
    color: #222222;
    line-height: 29px;
    text-align: center;
    font-style: normal;
  }
`

const FailButton = styled.button`
  width: 244px;
  background: #fdc448;
  height: 64px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  margin-top: 40px;
`;

const ConvertBox = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
`

const ConvertLabel = styled.label`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #222222;
  text-align: center;
  font-style: normal;
`