export default {
    "支持格式：PDF": 1,
    "精准度": 2,
    "为什么选择我们": 3,
    "读者": 4,
    "清晰愉悦的阅读体验": 5,
    "大语言模型": 6,
    "格式就是注意力": 7,
    "创作者": 8,
    "复制粘贴的快感": 9,
    "我们想说": 10,
    "更多": 11,
    "上传文件": 12,
    "处理记录": 13,
    "上传失败！": 14,
    "下载文件共需页数": 15,
    "输入邀请码": 16,
    "邀请码抵扣": 17,
    "每个邀请码可以抵扣500页内的文件": 18,
    "请输入邀请码": 19,
    "抵扣": 20,
    "正在上传PDF文件...": 21,
    "请上传正确的PDF文件！": 22,
    "正在上传": 23,
    "已上传": 24,
    "处理中": 25,
    "已完成": 26,
    "处理失败": 27,
    "确认": 28,
    "下载": 29,
    "支付下载": 30,
    "文件上传完成后可以确认生成格式，您可以同时上传更多文件": 31,
    "支付": 32,
    "登录": 33,
    "谷歌一键登录": 34,
    "正在登录": 35,
    "分享链接给新朋友注册，双方将获得100页Pro转换": 36,
    "复制专属链接": 37,
    "图片分享": 38,
    "扫描二维码注册": 39,
    "获得100页PDF Pro处理次数": 40,
    "退出账号": 41,
    "复制成功！": 42,
    "登录已过期，请重新登录！": 43,
    "保存至本地": 44,
    "优先抵扣页数：": 45,
    "推荐": 46,
    "识别正确率": 47,
    "填写邀请码": 48,
    "你将获得100页PDF PRO处理次数": 49,
    "提交": 50,
    "保留文件原排版": 51,
    "我们是为节省成本而来的": 52,
    "减少90%无效时间": 53,
    "不必再人工逐字逐句输入、校对、排版": 54,
    "高达99.5%的识别精确度让扫描件即刻能用": 55,
    "图片转文字？不够！": 56,
    "还要保留格式！我们只做最佳转换器！": 57,
    "从此告别转文字后比对着原稿一行一行辛苦排版工作": 58,
    "实验数据：PDF Pro服务将通过 LLM 纠错，将文本识别精度提升至99.5%": 59,
    "节省95%存储成本": 60,
    "图片变成文字": 61,
    "立刻节省95%存储空间": 62,
    "让钱花去刀刃上，我们来解决问题": 63,
    "实验数据：90MB文件处理后缩小至6MB，在高清化的同时有效释放接近94%的空间": 64,
    "定价": 65,
    "99.5%识别正确率": 66,
    "S$0.07/页": 67,
    "适合专业人士/企业/机构": 68,
    "尽可能高性价比 尽可能高效": 69,
    "让大语言模为您智能纠错": 70,
    "99%识别正确率": 71,
    "S$0.05/页": 72,
    "适合对精确度要求不高的个人": 73,
    "尽可能低廉": 74,
    "满足日常需求": 75,
    "图书转文字": 76,
    "减少95%存储成本": 77,
    "通过图片转文字进行存储": 78,
    "90MB轻松转为6MB": 79,
    "瘦身文件简简单单": 80,
    "你可以存更多": 81,
    "存储更多，看见更多": 82,
    "LLMs企业/学术研究机构": 83,
    "增强你的数据集": 84,
    "比竞争对手更完备": 85,
    "扫描件也可以成为模型可阅读的数据集": 86,
    "古早文档统统收入囊中": 87,
    "有效=高效": 88,
    "只做有效的事": 89,
    "节省时间、财力": 90,
    "把耗时耗力的重复性工作安心交给我们处理": 91,
    "电子书企业": 92,
    "降低95%成本": 93,
    "快速促进书籍、档案数字化": 94,
    "节省存储、传输": 95,
    "更好服务用户": 96,
    "给用户最快速提供最高清的图书": 97,
    "节省存储成本": 98,
    "小小的移动设备容纳更多书籍": 99,
    "再也不必担心存储空间": 100,
    "对自己更好": 101,
    "清晰的阅读，美好的体验": 102,
    "学生/老师": 103,
    "课件/文献语言障碍不复存在": 104,
    "扫描件变文本件": 105,
    "直接翻译就好啦": 106,
    "DDL杀手": 107,
    "快速复制引用，论文无忧": 108,
    "残障人士": 109,
    "让所有文件信息都能被读取": 110,
    "扫描件转换文本后即可成为能够被语音阅读的文件": 111,
    "让所有文件变成无障碍": 112,
    "关心在身边的他/她们": 113,
    "如果你希望为无障碍文件作出贡献": 114,
    "非常欢迎联系我们": 115,
    "我们将收录您的书籍，用于公益事业": 116,
    "语言学习者": 117,
    "快速提升语言能力": 118,
    "多种语言自由切换阅读，不必再浪费过多时间查阅": 119,
    "你可以学习更多": 120,
    "看见更多语种，看见更多可能": 121,
    "用户反馈": 122,
    "王先生": 123,
    "从事LLM相关领域研发": 124,
    "非常好，推荐！": 125,
    "很不错，有了这个工具再也不用带着纸质资料外出了。": 126,
    "文本提取准确度很高，大多数类型的扫描件转换完都没什么误差。": 127,
    "转换完体验太棒了！终于可以翻译成多种语言！阅读毫无障碍！": 128,
    "看了别人推荐过来的，很好用。": 129,
    "PDF到PDF的转化": 130,
    "图片转文字": 131,
    "使用场景": 132,
    "常见问题": 133,
    "博客": 134,
    "在线客服": 135,
    "Q&A": 136,
    "Blog": 137,
    "阅读爱好者": 138,
    "分享更多Pdf to Pdf相关内容": 139,
    "分享更多Pdf to Pdf相关内容、ocr文本提取技术、文件瘦身": 140,
    "常见问题，图片OCR文本识别FAQ": 141,
    "在这里，我们列出了一些关于pdf到pdf转换的常见问题。如果您没有找到所需的信息，请随时通过pdftopdf@leqi.ai与我们联系。": 142,
    "免费在线OCR—将PDF扫描件转换为文本": 143,
    "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!": 144,
    "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。": 145,
    "Pdf to Pdf| 高效工具全方位定价方案 | 量身定制满足您的需求": 146,
    "探索Pdf to Pdf软件的灵活定价计划，体验无与伦比的功能，享受个性化服务，现在注册更有超值优惠！": 147,
    "更多Pdf文档ocr文本提取处理记录": 148,
    "点击查看历史处理记录。": 149,
    "验证码错误！": 150,
    "邮箱登录": 151,
    "请输入邮箱": 152,
    "请输入密码": 153,
    "验证码已发送至邮箱": 154,
    "请输入验证码": 155,
    "设置密码": 156,
    "从事商务工作": 157,
    "刘先生": 158,
    "从事教师工作": 159,
    "赵先生": 160,
    "陈先生": 161,
    "个人财务管理者": 162,
    "张女士": 163,
    "可处理页书： ": 164,
    "语言": 165,
    "PDF格式转换工具主要特点": 166,
    "文字识别准确度高": 167,
    "高精度OCR文字识别技术，": 168,
    "高达99.5%": 169,
    "的识别精确度让扫描件即刻能用，": 170,
    "减少90%": 171,
    "无效时间。目前可识别中文简体、英文，后续还会新增日语、德语等其他语言。": 172,
    "批量处理高效快捷": 173,
    "支持一次性处理多张PDF扫描件，": 174,
    "在线批量": 175,
    "文字识别，一键批量格式转换，极大提高效率。": 176,
    "机器学习识别，准确度随着自动学习越来越高，识别速度快；识别经过加密处理，无需担心隐私。": 177,
    "图片转文字？不够！还要": 178,
    "保留格式": 179,
    "！PDF在线格式转换工具让你从繁琐的逐字输入文本的工作中解放，同时摆脱人工比对原稿逐字校对、排版。": 180,
    "压缩PDF扫描件": 181,
    "图片变文字": 182,
    "，立刻节省95%存储空间，经测试90MB文件处理后可缩小至6MB，在高清化的同时有效释放接近94%的空间，把钱花在刀刃上，PDFtoPDF": 183,
    "在线工具": 184,
    "高效为您解决问题！": 185,
    "为出行减负": 186,
    "摆脱纸质资料，随时随地打开手机即可阅读，给读者带来清晰愉悦的阅读体验。从PDF扫描件中提取文本，创建": 187,
    "可搜索、可复制和可访问": 188,
    "的内容，给创作者带来复制粘贴的快感！": 189,
    "OCR自动识别PDF扫描件，转换结果高清": 190,
    "只需将PDF扫描件上传，等待几秒，OCR将自动扫描并提取扫描件中的文字，一比一转换为更清晰的PDF文本文件，从而创建便于搜索、编辑和访问的内容。": 191,
    "支持移动端 & PC端": 192,
    "无论是手机上还是电脑上，都可以使用，直接通过网址进入，无需下载，方便快捷、不占内存！再也不用担心遇到临时有文件需要处理的情况啦，直接在手机移动端完成在线OCR识别，随时随地高效完成！": 193,
    "学生与老师": 194,
    "学生可以使用OCR技术将教材、笔记或研究资料中的文字快速提取出来，便于查找、整理学习材料或制作电子笔记。教师可以扫描讲义、参考书籍，通过OCR转换成文本，用于制作PPT、在线课程资料等。": 195,
    "做出海业务的企业": 196,
    "企业出海业务合同扫描件经OCR技术，一键转换为PDF文本文件，提升信息录入速度与准确性，便于后期翻译，让您快速了解合同内容，支持搜索查找重要条款，增强跨国合同管理的效率与便利性。": 197,
    "电子书企业使用OCR工具从扫描的文档、书籍、杂志等纸质材料中提取文字，快速实现纸质内容数字化，提升扫描件内容的可访问性、增强用户体验。": 198,
    "经常需要外出或习惯于用电子设备阅读的朋友，使用OCR在线工具将准备看的书扫描为电子文档，随时随地皆可阅读。还可以根据个人喜好调整电子文本布局、字体和颜色，营造个性化阅读环境。更重要的是彻底让我们摆脱传统厚重的纸质书本，最大程度节省空间、减轻负重。": 199,
    "OCR工具为语言学习者开启了一扇通往更广阔学习资源的大门，不仅能够将纸质材料瞬间转化为可编辑的电子文本，便于翻译、听写练习及个性化学习资料的创建，还可促进跨文化交流和学术研究的深化。": 200,
    "OCR技术为残障人士，尤其是视觉障碍者和阅读困难者，架起了通往信息自由和平等获取的桥梁。它将纸质材料瞬间转化为可听、可视的电子格式，满足多样化阅读需求，助力残障人士自主学习、高效沟通，极大地提升了他们在教育、工作和社会生活中的参与度与独立性。通过OCR，我们共同构建了一个更加包容和无障碍的世界。": 201,
    "如何将PDF扫描件转换为PDF文本？": 202,
    "上传PDF扫描件": 203,
    "OCR识别和提取": 204,
    "通过OCR技术": 205,
    "快速": 206,
    "识别pdf扫描件内容，提取结果精准度高达": 207,
    "99%＋": 208,
    "PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、节省存储空间、阅读体验更佳": 209,
    "PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、": 210,
    "节省存储空间、阅读体验更佳": 211,
    "邮箱：": 212,
    "立即转换 pdf": 213,
    "PDF扫描件文字提取利器，一键提升企业与学术文献处理效率": 214,
    "PDFtoPDF文字提取利器，可用于快速摘录文献、报纸、杂志上的重要信息，进行引用或创作，提高研究和写作效率。支持将手稿或旧文档数字化，方便存储和检索，是LLMs企业和学术研究机构的得力助手！": 215,
    "高效提取PDF扫描件文本——电子书企业必备工具": 216,
    "利用先进的OCR技术，PDFtoPDF网站能精准提取PDF扫描件中的文字内容，助力电子书企业快速将纸质书籍、文档转变为可编辑的文本，实现数字化飞跃。简化编辑流程，提升出版效率，为您的电子书项目注入无限创意与灵活性。立即体验，开启智慧出版新时代。": 217,
    "PDF扫描件图片文本一键提取：从图片到清晰文字，升级阅读爱好者体验": 218,
    "专为阅读爱好者设计，这款OCR工具能巧妙地从PDF扫描件中抓取图片内的文字，将其转化为清晰、易读的文本格式。从此，不再受限于扫描文档的阅读不便，享受如纸质书般的流畅与清晰，让每一次阅读都成为一种享受。提升文本质量，尽享无界阅读新乐趣，现在就加入我们，开启您的舒适阅读之旅。": 219,
    "教育助力：PDF扫描件文本轻松提取，优化学习资料，提升教学体验": 220,
    "专为学生和教师打造的OCR解决方案，高效从PDF扫描件及图片中精准提取文本，转化成清晰可编辑的内容，让繁琐的学习资料瞬间变得井井有条。无论是论文研读、讲义整理还是教材制作，都能显著提升效率，助力学术研究与课堂教学迈上新台阶。立即体验，开启智慧学习新篇章。": 221,
    "OCR文字识别技术：将PDF扫描件转化为语音可读文本，构筑无障碍信息桥梁": 222,
    "利用先进的Text Recognition OCR技术，将PDF扫描文档轻松转换成可编辑、可语音阅读的文本，打破了残障人士阅读障碍。无论是学习资料、工作文件还是日常资讯，都能瞬间转化，让信息获取不再受限，促进数字内容的全面无障碍。": 223,
    "OCR技术赋能语言学习：PDF扫描件秒变文本，多语言翻译随心阅": 224,
    "OCR文本提取 & 翻译神器：一键将PDF扫描文档转换成可编辑文本，无论是外语教材还是原版文献，都能即扫即译。支持多种语言切换，大大节省查阅时间，多语言阅读无障碍，学习加速新体验！": 225,
    "上传PDF扫描件，轻松转换文本——高效PDF转文字工具": 226,
    "端点": 227,
    "认证": 228,
    "需要'web'类型的JWT Token": 229,
    "请求头": 230,
    "响应": 231,
    "成功": 232,
    "失败": 233,
    "Python代码示例": 234,
    "使用示例": 235,
    "参数": 236,
    "需要'test_company'类型的JWT Token": 237,
    "PDF文件，作为multipart/form-data上传": 238,
    "错误信息，例如：No file part in the request": 239,
    "PDF处理任务的ID": 240,
    "API": 241,
    "正式密钥": 242,
    "测试环境": 243,
    "接入文档": 244,
    "申请发票": 245,
    "获取密钥": 246,
    "密钥:": 247,
    "复制": 248,
    "充值": 249,
    "剩余：": 250,
    "页": 251,
    "登录获取密钥": 252,
    "可免费测试3次PDF文件": 253,
    "1. 获取客户端密钥": 254,
    "复制代码": 255,
    "2. 获取访问令牌": 256,
    "3. 上传PDF文件": 257,
    "4. 获取PDF处理状态": 258,
    "5. 下载PDF文件": 259,
    "企业抬头:": 260,
    "请输入": 261,
    "税号:": 262,
    "开户行:": 263,
    "电话:": 264,
    "地址:": 265,
    "申请": 266,
    "10000页": 267,
    "50000页": 268,
    "复制成功": 269,
    "请确保将 `<YOUR_WEB_JWT_TOKEN>`, `<YOUR_TEST_COMPANY_JWT_TOKEN>`,": 270,
    "`<YOUR_CLIENT_SECRET>`, `<YOUR_TASK_ID>`, 和 `path/to/your/file.pdf` 替换为合适的值，以进行测试。": 271,
    "请完善所有信息": 272,
    "提交成功": 273,
    "感谢您的提交，我们将在10个工作日内处理您的开票请求，如有疑问或其他要求请联系我们的邮箱：pdftopdf@leqi.ai": 274,
    "免费处理文件数": 275,
    "Apple ID": 276,
    "提示": 277,
    "您已享受一次免费文件处理，您还可以通过分享您的链接或邀请码邀请更多新用户注册，获取免费处理100页": 278,
    "复制邀请链接": 279,
    "下载邀请海报": 280,
    "文本可复制/检索": 281,
    "图片转文字节省空间示例图": 282,
    "图片转文字存储更多示例图": 283,
    "Pdf扫描件": 284,
    "OCR提取文本后的PDF文件": 285,
    "OCR扫描提取文本示例图": 286,
    "移动端&PC端页面样式示例图": 287,
    "学生与老师使用OCR工具场景图": 288,
    "海外业务使用PDF文字提取工具场景图": 289,
    "电子书企业使用pdf to pdf提取文字场景图": 290,
    "阅读爱好者使用文字提取工具场景图": 291,
    "语言学习者使用ocr工具场景图": 292,
    "残障人士使用OCR工具将内容可听化": 293,
    "高扫描精度，节省时间": 294,
    "压缩PDF，节省存储空间": 295,
    "语言切换按钮": 296,
    "扫描后增强数据集示例图": 297,
    "省钱省时示例图": 298,
    "图片提取文字后节省存储示例图": 299,
    "PDF扫描前后对比图": 300,
    "移动端节省存储成本示例图": 301,
    "扫描件提取文本直接翻译": 302,
    "快速复制示例图": 303,
    "扫描件提取文本可被读取示例图": 304,
    "书本ocr收录": 305,
    "扫描件提取文本供翻译": 306,
    "文本提取后可翻译成所需语言": 307,
    "正在制作中...": 308,
    "正在下载中...": 309,
    "请选择输出格式": 310,
    "请选择翻译目标语言": 311,
    "预览": 312,
    "收起处理文件": 313,
    "展开处理文件": 314,
    "OCR": 315,
    "请选择源文件中包含的语言，将提升识别精度，如未选择则智能识别": 316,
    "源文件包含语言：": 317,
    "请选择": 318,
    "中文": 319,
    "英文": 320,
    "输出文件格式：": 321,
    "制作中": 322,
    "开始制作": 323,
    "正在转换文件格式:": 324,
    "翻译": 325,
    "翻译目标语言：": 326,
    "翻译中": 327,
    "开始翻译": 328,
    "正在翻译文件:": 329,
    "预览页面加载中，请稍后…": 330,
    "加载失败": 331,
    "重新制作": 332,
    "邮箱或密码错误": 333,
    "Facebook": 334,
    "可处理页数：": 335,
    "请输入验证码！": 336,
    "请输入密码！": 337
}