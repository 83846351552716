import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../libs/utils";

const GlobalGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    useEffect(() => {        
        if (!isMobile()) {
            window.location.href = 'https://pdftopdf.ai/'
            return
        }
    }, [isLoggedIn, navigate]);

    return <>{children}</>;
};

export default GlobalGuard;
